import React, { useContext } from 'react';
import { AuthContext } from '../provider/AuthProvider';
import { NavigationContainer } from '@react-navigation/native';
import * as Linking from 'expo-linking';

// function HeaderView() {
  
//   return <span>Path : {location.pathname}</span>
// }

import Main from './MainStack';
import Auth from './AuthStack';
import Loading from '../screens/utils/Loading';

const pdfSource = require('../../assets/MarketWebsTandC.pdf');

export default () => {
	const auth = useContext(AuthContext);
	const user = auth.user;
	Linking.getInitialURL().then((url) => {
		const { hostname, path, queryParams } = Linking.parse(url);
		if (queryParams?.termsandconditions == '1') {
			Linking.openURL(pdfSource);
		}
	});
	return (
		<NavigationContainer>
			{user == null && <Loading />}
			{user == false && <Auth />}
			{user == true && <Main />}
		</NavigationContainer>
	);
};
