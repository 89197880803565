import React, { useState, useEffect } from "react";
import { StatusBar } from "expo-status-bar";
import {
  ScrollView,
  TouchableOpacity,
  View,
  KeyboardAvoidingView,
  Image,
  StyleSheet,
} from "react-native";
import { supabase } from "../../initSupabase";
import { AuthStackParamList } from "../../types/navigation";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { useCookies } from 'react-cookie';
import {
  Layout,
  Text,
  TextInput,
  Button,
  useTheme,
  themeColor,
  Section,
  SectionContent,
} from "react-native-rapi-ui";

import * as Linking from 'expo-linking';



export default function ({
  navigation,
}: NativeStackScreenProps<AuthStackParamList, "Login">) {
  const { isDarkmode, setTheme } = useTheme();
  const [email, setEmail] = useState<string>("");
  const [code, setCode] = useState<string>("");
  const [cookies, setCookie] = useCookies(['promo_code']);
  const [password, setPassword] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [codeSent, setCodeSent] = useState<boolean>(false);
  const [subs, setSubs] = useState(null);
  const [updateSubs, setUpdateSubs] = useState(true);
  const [prices, setPrices] = useState(null);
  const [trialAvail, setTrialAvail] = useState(null);
  const [showLogin, setShowLogin] = useState<boolean>(false);
  const [promo, setPromo] = useState(null);

  setTheme("dark");

  useEffect(()=> {
    if (cookies?.promo_code) {
      checkPromoCode(cookies?.promo_code)
    }
  }, [])

  useEffect(() => {
    async function getSubsInfo() {
      const { data: subs_info, error } = await supabase.functions.invoke('get-subscriptions', {body: {test: false}});
      let activeSubs = [];
      subs_info.subscriptions?.forEach((sub)=>{
        if (sub.status != 'canceled') {
          activeSubs.push(sub);
        }
      });
      setSubs(activeSubs);
      setPrices(subs_info.prices);
      setTrialAvail(subs_info.trial_avail);
    }
    if (updateSubs) {
      getSubsInfo();
      setUpdateSubs(false);
      setLoading(false);
    }
  }, [updateSubs])


  async function checkPromoCode(code) {
    const promocheck = await supabase.functions.invoke('check-promo', {body: {code: code}});
    if (promocheck?.data?.length > 0) {
      let promo = promocheck.data[0];
      promo.message = promo.metadata.message;
      setPromo(promo);
    } else {
      setPromo({empty:true})
    }
  }

  function promotion() {
    if (promo != undefined && promo?.id != undefined) {
      return <Section style={{ padding: 20, borderColor: '#FF0000', borderWidth: 10 }} borderRadius={20} >  
      <Text style={{alignSelf:'center', fontWeight:"bold"}}>{cookies?.promo_code} Special:</Text><Text style={{alignSelf:'center', fontStyle:'italic'}}>New subscribers get {promo?.message}</Text>
      </Section>
    }
  }
  

  function subscriptions() {
    if (( !subs || !prices ) || loading ) {
      return <Text>Loading...</Text>;
    } else {
      // if (!userNeedsInit) {
        const plans = [];
        prices?.forEach((price) => {
          let finplan = plans.find( p => p.product_id == price.product.id )
          if (!finplan) {
            finplan = {
              prices: [],
              button_type: trialAvail ? 'trial' : 'subscribe',
              name: price.product.name,
              description: price.product.description,
              interval: price.recurring.interval,
              unit_amount: price.unit_amount,
              id: price.id,
              product_id: price.product.id,
              features: price.product.features,
              imgage: price.product.images[0],
              link: price.product.metadata.link,
            };
            plans.push(finplan);
          }
          // if (price.recurring.interval != 'year') {
          finplan.prices.push({id: price.id, unit_amount: price.unit_amount, interval: price.recurring.interval, message: price.metadata.message});
          // }
          // let plan = {
          //   button_type: trialAvail ? 'trial' : 'subscribe',
          //   name: price.product.name,
          //   description: price.product.description,
          //   interval: price.recurring.interval,
          //   unit_amount: price.unit_amount,
          //   id: price.id,
          //   features: price.product.features,
          //   imgage: price.product.images[0],
          //   link: price.product.metadata.link,
          // }
          // plans.push(plan);
        });
        
        const formatted_plans = [];
        plans.forEach((plan) => { 
          const features = [];
          plan.features.forEach((feat)=>{features.push(
          <Text style={{
            padding: 5,
            paddingBottom: 5,
            alignSelf: "left",
          }} key={feat.name}>
            * {feat.name}
          </Text>)});

          formatted_plans.push(<Section style={{ marginTop: 20 }} key={plan.id}>
          <SectionContent>
            <Text fontWeight="bold"
              style={{
                alignSelf: "center",
                padding: 30,
                paddingBottom: 5,
              }}
              >{plan.name}</Text>
            <Text>{plan.description}</Text>
            <Text fontWeight="bold"
              style={{
                alignSelf: "center",
                alignItems: "center",
                textAlign: "center",
                padding: 10,
                paddingBottom: 20,
              }}>
            {plan.prices.map((p,index)=>(
              <>
              {index > 0 && <><p>or</p></>}
                ${p.unit_amount/100}/{p.interval}
                {p.message && <Text style={{margin:10, fontSize: 'small'}}>{p.message}</Text>}
              </>
            ))}
            </Text>
            <Text style={{
                    alignSelf: "center",
                    fontSize: 'small'
                  }}>{trialAvail && <>15 Day Free Trial. </>} Cancel Any Time.</Text>
            <Button 
              style={{
                alignSelf: "center",
                padding: 30,
                margin: 20,
              }}
              text={plan.button_type == 'manage' ? 'Manage Your Subscription' : plan.button_type == 'trial' ? 'Subscribe' : 'Subscribe'}
              onPress={async () => {
                setShowLogin(true);
              }}
            />
            {features}
            <Button 
              style={{
                alignSelf: "center",
                padding: 10,
                margin: 30,
                height:20,
              }}
              color="#9999ff"
              textStyle={{color:"#000000"}}
              text="Learn More At TradingView.com"
              onPress={async () => {
                Linking.openURL('https://www.tradingview.com/script/Aor7lrBY-MarketWebs/?aff_id=138731')
              }}
            />
            {/* <Text style={{padding:10}}> </Text> */}
          </SectionContent>
        </Section>)})
        return formatted_plans;
      // }
    }
  }

  async function login() {
    setLoading(true);
    const { data, error } = await supabase.auth.verifyOtp({
      email: email,
      type: 'email',
      token: code
      // password: password,
    });
    if (!error && !data) {
      setLoading(false);
    }
    if (error) {
      setLoading(false);
      alert(error.message);
    }
  }

  async function sendCode() {
    setLoading(true);
    const { res, err } = await supabase.auth.signInWithOtp({
      email: email
    });
    if (!err) {
      setCodeSent(true);
      setLoading(false);
    }
  }

  const pdfSource = require('../../../assets/MarketWebsTandC.pdf').toString();

  const pdfLink = pdfSource.toString();

  return (
    <KeyboardAvoidingView behavior="height" enabled style={{ flex: 1 }}>
      <Layout>
        <ScrollView
          contentContainerStyle={{
            flexGrow: 2,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <View
            style={{
              // flex: 1,
              justifyContent: "center",
              alignItems: "center",
              // backgroundColor: isDarkmode ? "#17171E" : themeColor.white100,
              padding:30
            }}
          >
            <Button
              text="Sign In to Manage your Subscription"
              onPress={() => {
                setShowLogin(true);
              }}
              style={{
                padding: 10,
                margin: 10,
                height:20,
              }}
              color="#9999ff"
              textStyle={{color:"#000000"}}
              disabled={loading}
            />
            <Image
              resizeMode="contain"
              style={{
                height: 120,
                width: 220,
              }}
              source={require("../../../assets/images/mwebslogo.png")}
            />
            
            <View style={{flexDirection:'column'}}>
              <View>
                <Text 
                  fontWeight="bold"
                  style={{
                    alignSelf: "center",
                    // padding: 30,
                    // selfAlign: "center"
                  }}
                  size="h3">
                  A Roadmap for Trading
                </Text>
                <Text style={{
                    alignSelf: "center",
                    padding: 20,
                    // selfAlign: "center"
                  }}>
                  By analyzing market volume at price levels <br/>during discrete periods of time, the MarketWebs <br/>provide easy to identify price trends and targets <br/>across all time frames.<br/>
                </Text>
            </View>
            <View>
              
            <Image
              resizeMode="contain"
              style={{
                height: 120,
                width: 300,
                alignSelf: 'center'
              }}
              source={require("../../../assets/images/DemoImage.png")}
            />
            
            {/* <Button 
              style={{
                alignSelf: "center",
                padding: 10,
                margin: 10,
              }}
              color="#9999ff"
              textStyle={{color:"#000000"}}
              text="Learn More At TradingView.com"
              onPress={async () => {
                Linking.openURL('https://www.tradingview.com/script/Aor7lrBY-MarketWebs/')
              }}
            /> */}
            </View>
            </View>
          </View>
          { !showLogin && 
          <View style={{
              // flex: 1,
              alignItems: "center",
              justifyContent: "center",
            }}>
              {promotion()}
            
            {subscriptions()}
          </View> }
          { showLogin && <View 
            style={{
              flex:3,
              paddingHorizontal: 20,
              paddingBottom: 10,
              backgroundColor: isDarkmode ? themeColor.dark : themeColor.white,
            }}
          ><>
            { !codeSent &&
            <>
              <Text
                // fontWeight="bold"
                style={{
                  alignSelf: "center",
                  padding: 30,
                }}
                size="h3"
              >
                Pardon the clunkiness as we get up and rolling!  
              </Text>
              <Text
                fontWeight="bold"
                style={{
                  alignSelf: "center",
                  padding: 30,
                  selfAlign: "center"
                }}
                size="h3"
              >
                Verify your Email to Create or Manage Your Subscription
              </Text>
              </> 
            }
            <Text>Email</Text>
            <TextInput
              containerStyle={{ marginTop: 15 }}
              placeholder="Enter your email"
              value={email}
              autoCapitalize="none"
              autoCompleteType="email"
              autoCorrect={false}
              keyboardType="email-address"
              onChangeText={(text) => setEmail(text)}
              editable={!codeSent}
              onSubmitEditing={ ({nativeEvent: {text, eventCount, target}}) => { setEmail(text); sendCode();} }
            />
            <Text style={{
              alignSelf: "center",
              paddingTop: 20,
              fontSize: 'small'
            }}>
            By clicking the button below you are agreeing to MarketWebs’s <a href={pdfLink}>Terms of Service</a>, <br/>
            as well as receiving product and marketing communications from MarketWebs LLC. <br/>
            You may unsubscribe from these communications at any time.
            </Text>
            <Button
              text={codeSent ? "Re-send Code" : "Send 6-Digit Code"}
              onPress={() => {
                sendCode();
              }}
              style={{
                marginTop: 20,
              }}
              disabled={loading}
            />
            {codeSent && <><View style={{paddingTop: 30}}>
              <Text>6-Digit Code</Text>
              <TextInput
                containerStyle={{ marginTop: 15 }}
                placeholder="Enter 6-Digit Code"
                value={code}
                autoCapitalize="none"
                autoCompleteType="off"
                autoCorrect={false}
                keyboardType="numeric"
                onChangeText={(code) => setCode(code)}
                onSubmitEditing={()=> login() }
              />
              <Button
                text="Submit Code"
                onPress={() => {
                  login();
                }}
                style={{
                  marginTop: 20,
                }}
                disabled={!codeSent}
              /> 
            </View>
            </>
            }
            </>
            </View>
          }
          {!showLogin && <View 
            style={{
              flex:1,
              // paddingHorizontal: 20,
              // paddingBottom: 10,
              // backgroundColor: isDarkmode ? themeColor.dark : themeColor.white,
            }}>
            </View>
            }
        </ScrollView>
      </Layout>
    </KeyboardAvoidingView>
  );
}


{/* <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                marginTop: 15,
                justifyContent: "center",
              }}
            >
              <Text size="md">Don't have an account?</Text>
              <TouchableOpacity
                onPress={() => {
                  navigation.navigate("Register");
                }}
              >
                <Text
                  size="md"
                  fontWeight="bold"
                  style={{
                    marginLeft: 5,
                  }}
                >
                  Register here
                </Text>
              </TouchableOpacity>
            </View> */}



// const styles = StyleSheet.create({
//   container: {
//     flex: 3,
//     paddingHorizontal: 20,
//     paddingBottom: 20,
//     backgroundColor: themeColor.dark,
//     width: '50%',
//     justifyContent: 'space-between',
//     padding: 20,
//     margin: 10,
//   },
//   top: {
//     flex: 0.3,
//     backgroundColor: 'white',
//     borderWidth: 5,
//     borderTopLeftRadius: 20,
//     borderTopRightRadius: 20,
//   },
//   middle: {
//     flex: 0.3,
//     backgroundColor: 'beige',
//     borderWidth: 5,
//   },
//   bottom: {
//     flex: 0.3,
//     backgroundColor: 'pink',
//     borderWidth: 5,
//     borderBottomLeftRadius: 20,
//     borderBottomRightRadius: 20,
//   },
// });
            