import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from '../provider/AuthProvider';
import { View, ScrollView, StyleSheet, Linking, Image } from "react-native";
import { MainStackParamList } from "../types/navigation";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { supabase } from "../initSupabase";
import SwitchSelector from "react-native-switch-selector";
import { useCookies } from 'react-cookie'
import {
  Layout,
  Button,
  Text,
  TextInput,
  TopNav,
  Section,
  SectionContent,
  useTheme,
  themeColor,
} from "react-native-rapi-ui";
import { Ionicons } from "@expo/vector-icons";

export default function ({
  navigation,
}: NativeStackScreenProps<MainStackParamList, "MainTabs">) {
  const auth = useContext(AuthContext); 
  const { isDarkmode, setTheme } = useTheme();
  const [cookies, setCookie] = useCookies(['promo_code'])
  const [loading, setLoading] = useState(true);
  const [profile, setProfile] = useState({});
  const [error, setError] = useState(null);
  const [user, setUser]= useState(null);
  const [userNeedsInit, setUserNeedsInit] = useState(false);
  const [subs, setSubs] = useState(null);
  const [updateSubs, setUpdateSubs] = useState(true);
  const [prices, setPrices] = useState(null);
  const [trialAvail, setTrialAvail] = useState(null);
  const [validUsername, setValidUsername] = useState(false);
  const [yearly, setYearly] = useState(false);
  const [yearlyAvailable, setYearlyAvailable] = useState(false);
  const [code, onChangeCode] = useState('');
  const [promo, setPromo] = useState(null);
  const [promoError, setPromoError] = useState(null);
  const [codeChecking, setCodeChecking] = useState(false);
  const [price_item, setPriceItem] = useState(null);

  const [newUserName, setNewUserName] = React.useState('');

  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    if (cookies?.promo_code) {
      checkPromoCode(cookies?.promo_code)
    }
    setTheme("dark");
    async function getUserInfo() {
      try {
        const { data: _userinfo, error } = await supabase.auth.getUser();
        if (_userinfo.user.id !== undefined) {
          const { data: _profile, profError } = await supabase.from('profiles').select().eq('id', _userinfo.user.id);
          setUser(_userinfo);
          setProfile(_profile[0]);
          if (_profile[0]?.full_name == null || _profile[0]?.username == null) {
            setUserNeedsInit(true);
          } 
        }
      } catch (error) {
        // console.log(error);
      }
    }
    getUserInfo(); 
    
    // checkPromoCode('webtest');
  }, [])

  async function checkTVusername(username) {
    if (username == undefined) {
      return;
    }
    const stat = await supabase.functions.invoke('get-tv-username', {body: {username: username}});
    if (stat?.data?.username?.toLowerCase() == username?.toLowerCase()) {
      return true;
    } else {
      return false;
    }
  }

  useEffect(() => {
    async function getSubsInfo() {
      const { data: subs_info, error } = await supabase.functions.invoke('get-subscriptions', {body: {test: false}});
      let activeSubs = [];
      subs_info.subscriptions?.forEach((sub)=>{
        if (sub.status != 'canceled') {
          activeSubs.push(sub);
        }
      });
      setSubs(activeSubs);
      setPrices(subs_info.prices);
      setTrialAvail(subs_info.trial_avail);
    }
    if (updateSubs) {
      getSubsInfo();
      setUpdateSubs(false);
      setLoading(false);
    }
  }, [updateSubs])

  async function checkPromoCode(code) {
    setCodeChecking(true);
    // console.log(`checking`, code)
    const promocheck = await supabase.functions.invoke('check-promo', {body: {code: code}});
    console.log(promocheck);
    onChangeCode(code);
    if (promocheck?.data?.length > 0) {
      let promo = promocheck.data[0];
      if (!promo.first_time_transaction || trialAvail) {
        promo.message = "You're getting " + promo.metadata.message;
        setPromo(promo);
      } else {
        setPromo({empty:true})
        setPromoError('Code is only valid for first time subscriptions, contact support@marketreader.com for help.')
      }
    } else {
      setPromo({empty:true})
      setPromoError('Invalid Code.')
    }
    setCodeChecking(false);
  }
  

  function formatDate(dateString) {
    if (dateString == null) {
      return '';
    }
    const date = new Date(dateString);
    if (!isNaN(date)) {
      let day = date.getDate().toString();
      let month = (date.getMonth() + 1).toString(); // Months are 0-based
      let year = date.getFullYear();
    
      // Adding leading zeros if day or month is less than 10
      day = day.length < 2 ? '0' + day : day;
      month = month.length < 2 ? '0' + month : month;
    
      return `${month}/${day}/${year}`;
    }
    return '';
  }

  function subscriptions() {
    if (!profile || ( !subs || !prices ) || loading ) {
      return <Text>Loading...</Text>;
    } else {
      if (!userNeedsInit) {
        let plans = [];
        if (subs.length > 0) {
          subs.forEach((sub)=>{
            let price = prices?.find(x => x.id == sub.plan.id);
            let plan = sub.plan;
            plan.status = sub.status;
            plan.name = price?.product.name;
            plan.description = price?.product.description;
            plan.current_period_end = sub.current_period_end;
            plan.cancel_at_period_end = sub.cancel_at_period_end;
            plan.trial_end = sub.trial_end;
            plan.button_type = 'manage';
            plan.status = sub.status;
            plan.unit_amount = plan.amount;
            plan.features = price.product.features;
            plan.prices = [{id: plan.id, unit_amount: plan.unit_amount, interval: plan.interval}]
            plans.push(plan);
          })
          // return <Text>{JSON.stringify(plans)}</Text>  
        }
        else {
          prices?.forEach((price) => {
            let finplan = plans.find(p => p.product_id === price.product.id);
            if (!finplan) {
              finplan = {
                prices: [],
                button_type: trialAvail ? 'trial' : 'subscribe',
                name: price.product.name,
                description: price.product.description,
                interval: price.recurring.interval,
                unit_amount: price.unit_amount,
                id: price.id,
                product_id: price.product.id,
                features: price.product.features,
                imgage: price.product.images[0],
                link: price.product.metadata.link,
              };
              plans.push(finplan);
            }
            // if (price.recurring.interval == 'year') {
              finplan.prices.push({id: price.id, unit_amount: price.unit_amount, interval: price.recurring.interval, message: price.metadata.message});
            // }
          });
          
        }
        const formatted_plans = [];
        plans.forEach((plan) => { 
          const features = [];
          // let price_item = {}
          
          let yearly_price = plan.prices.find(p => p.interval == "year")
          let monthly_price = plan.prices.find(p => p.interval == "month")
          let yearlyAvailable = false;
          if (yearly_price) {
            yearlyAvailable = true;
          }
          const price_item = yearly ? yearly_price : monthly_price;
          
          plan.features.forEach((feat)=>{features.push(
          <Text key={feat.name} style={{
            padding: 5,
            paddingBottom: 5,
            alignSelf: "left",
          }}>
            * {feat.name}
          </Text>)});
          
          formatted_plans.push(
          <Section style={{ margin: 20 }} key={plan.id + 'sub'}>
            <SectionContent>
              {(plan.button_type == 'trial' || plan.status == 'paused') && <Text fontWeight="bold"
                style={{
                  fontSize: 'large',
                  alignSelf: "center",
                  padding: 10,
                  paddingBottom: 40,
                }}>
                {plan.button_type == 'trial' ? 'One more step -- click "Start Your Trial" below to get started!' : plan.status == 'paused' ? 'Your scubcription is currently on pause.  Click below to re-activate!' : ''}
              </Text>}
              <Text fontWeight="bold"
                style={{
                  alignSelf: "center",
                  padding: 30,
                  paddingBottom: 5,
                }}
                >{plan.name}</Text>
              <Text>{plan.description}</Text>
              { plan.button_type != 'manage' && 
              // NOT CURRENTLY SUBSCRIBED -- SHOW MONTHLY/YEARLY TOGGLE, SHOW PRICES, SHOW PROMO CODE ENTRY BOX
              <>
                { yearlyAvailable && plan.button_type != 'manage' && 
                  <Section style={{alignSelf: 'center', width: 300, marginTop: 30}}><SwitchSelector
                  options={[{label: "Monthly", value: "month"}, {label: "Yearly", value: "year"}]}
                  initial={0}
                  onPress={value => setYearly(value == "year")}
                  textColor='#9998ff' 
                  selectedColor='#000000'
                  buttonColor='#9998ff'
                  borderColor='#9998ff'
                  hasPadding
                  bold
                  height={30}
                /></Section>}
                
                <Text fontWeight="bold"
                  style={{
                    fontSize: 'large',
                    alignSelf: "center",
                    padding: 10,
                    paddingBottom: 20,
                  }}>
                    ${price_item.unit_amount/100}/{price_item.interval}
                    {price_item.message && <Text style={{margin:10, fontSize: 'small'}}>{price_item.message}</Text>}
                  </Text>
                  <Text style={{
                    alignSelf: "center",
                    fontSize: 'small'
                  }}>{trialAvail && <>15 Day Free Trial. </>} Cancel Any Time.</Text>
                  {plan.button_type != 'manage' &&
                    <>
                    <Text style={{marginTop:30, marginBottom:10, alignSelf:'center'}}>
                      <TextInput
                        style={{marginRight:30}}
                        value={code}
                        placeholder={'Have a Promo Code?'}
                        autoCapitalize="characters"
                        onChangeText={onChangeCode}
                        onBlur={()=>{checkPromoCode(code)}}
                        onSubmitEditing={()=>{checkPromoCode(code)}}
                        maxLength={30}
                        editable={promo?.id == undefined}
                        onEndEditing={()=>{checkPromoCode(code)}}
                      />
                      {promo != undefined && <>{ promo?.id != undefined ? <> ✓ </> : <> X </> }</>}
                    </Text>
                    {/* {promo != undefined && promo?.id != undefined ? <Text style={{alignSelf:'center', marginTop:10, marginBottom:20}}>You're getting {promo?.message}</Text> : <Text style={{alignSelf:'center', marginTop:10, marginBottom:20}}>Invalid Code</Text>} */}
                    {promo != undefined && <Text style={{alignSelf:'center', marginTop:10, marginBottom:20}}>{promo?.id != undefined ? <>{promo?.message}</> : <>{promoError}</>}</Text>}
                    </>}
                  {!trialAvail && <Text style={{margin:10}}></Text>}
                  </>}
                <Button 
                style={{
                  alignSelf: "center",
                  padding: 30,
                  marginBottom:40,
                }}
                text={codeChecking ? 'Checking...' : plan.button_type == 'manage' ? 'Manage Your Subscription' : plan.button_type == 'trial' ? 'Start Your Free Trial' : 'Subscribe'}
                onPress={async () => {
                  setLoading(true);
                  const { data: url, error } = await supabase.functions.invoke('stripe-create-checkout', {body: {email: user?.user.email, name: profile?.full_name, tvusername: profile?.username, trialavail: trialAvail, pricecode: price_item?.id, product: plan.product, promotion_code: promo?.id }});
                  if (error) {
                    // console.log(error);
                  }
                  try {
                    window.location.href = url;
                  } catch {
                    setLoading(false);
                  }
                }}
                disabled={codeChecking}
              />
              <hr style={{width: 200, marginTop:20, marginBottom: 20}}/>



                {(plan.button_type != 'manage') && features}
                {plan.button_type == 'manage' && plan.status != 'paused' && <>
                <Text style={{
                  
                }}>
                  <p>You have access to MarketWebs on <Text style={{color:'#ffffff',textDecorationLine: 'underline'}} onPress={() => { Linking.openURL('https://www.tradingview.com/?aff_id=138731'); }}>TradingView</Text>!</p>
                  <p>
                  Instructions:<br/></p>
                  <ul>
                  <li>You will have MarketWebs appear on your <Text style={{color:'#ffffff',textDecorationLine: 'underline'}} onPress={() => { Linking.openURL('https://www.tradingview.com/chart/?symbol=AMEX%3ASPY&aff_id=138731'); }}>chart</Text>'s indicators <br/> menu, under "Invite Only Scripts"<br/></li> 
                  <li>Be sure to "Star"/"Favorite" the script so you will be notified <br/>about updates!</li>
                  <li>To manage your subscription in the future, return to this site.</li>
                  <li>Set your Y-axis to "Scale price chart only"</li>
                  <li>Due to complex calculations, set your charts to Extended <br/>Trading Hours (ETH)</li>
                  <li>For intraday trading, it is most accurate to subscribe to <br/> the <Text style={{color:'#ffffff',textDecorationLine: 'underline'}} onPress={() => { Linking.openURL('https://www.tradingview.com/us-markets-bundle/?aff_id=138731'); }}>US Equities Bundle</Text></li>
                  </ul>
                </Text>
                <Image
                    resizeMode="contain"
                    style={{
                      height: 320,
                      width: 620,
                    }}
                    source={require("../../assets/images/getStarted.gif")}
                  />
                </>}
              <Button 
                  style={{
                    alignSelf: "center",
                    padding: 40,
                    marginTop: 10,
                    height: 30,
                  }}
                  color="#9999ff"
                  text="Click Here for More Details and Release Notes"
                  onPress={async () => {
                    Linking.openURL(plan.link)
                  }}
                />
            </SectionContent>
          </Section>)
          })
        return formatted_plans;
      }
    }
    return 
  }

  

  function userInfo() {
    // const [name, setName] = useState();
    const [changed, setChanged] = useState(false);

    async function saveInfo() {
      setChanged(false);
      if (profile.full_name == undefined) {
        alert("Please enter your full name.");
        setChanged(false);
        return;
      }
      const valid = await checkTVusername(profile.username);
      if (valid) {
        // HERE WE CREATE THE USER INSIDE OF STRIPE.
        // await supabase.functions.invoke('stripe-customer-info', {body:{email: user?.user?.email, tvusername: profile?.username, name: profile?.full_name, force_create: true}});
        await supabase.from('profiles').update({full_name: profile?.full_name, username: profile?.username}).eq('id', profile?.id);
        setUserNeedsInit(false);
        setUpdateSubs(true);
      } else {
        alert("Check your TradingView username again, none with that exists.");
      }
    }
    
    if (!profile) {
      return;
    } else {
      if (userNeedsInit) {
        return <View style={{
          flex: 3,
          paddingHorizontal: 20,
          paddingBottom: 20,
          backgroundColor: isDarkmode ? themeColor.dark : themeColor.white,
        }}>
          {(userNeedsInit || true) && <View style={{
            flex: 1,
            paddingHorizontal: 20,
            // paddingBottom: 5,
            backgroundColor: isDarkmode ? themeColor.dark : themeColor.white,
          }}>
            <Text style={{ marginTop: 15 }}>Welcome!  
              <p>We're going to need your name and your TradingView username to get you set up.</p>
              <p>( Don't have TradingView?  Use <a style={{color:'#FFFFFF'}} href="https://tradingview.com/pricing/?aff_id=138731">this link</a> to get $15 credit towards a new subscription! )</p>
              <p>Once you have a TradingView account, you can find your username <a style={{color:'#FFFFFF'}} href='https://tradingview.com/u/?aff_id=138731'>here</a>.</p>
            </Text>
            </View>}
          <Text>Your Name:</Text>
          <TextInput
            containerStyle={{ marginTop: 15 }}
            placeholder="Name"
            value={ (profile != null && profile?.full_name != null) ? profile.full_name : '' }
            autoCapitalize="words"
            autoCompleteType="off"
            autoCorrect={false}
            onChangeText={(name) => { setProfile({...profile, full_name: name}); setChanged(profile?.full_name != null && profile?.username != null); }}
          />
          <Text style={{ marginTop: 15 }}>Your TradingView Username:</Text>
          <TextInput
            containerStyle={{ marginTop: 15 }}
            placeholder="TradingView Username"
            value={ (profile != null && profile?.username != null) ? profile.username : '' }
            autoCapitalize="off"
            autoCompleteType="off"
            autoCorrect={false}
            onChangeText={(name) => { 
              setProfile({...profile, username: name}); 
              setChanged(profile?.full_name != null && profile?.username != null); }}
            onSubmitEditing={()=>{ saveInfo(); }}
          />
          <Button
            text= {!changed && profile?.full_name != null && profile?.username != null ? "Saving..." : "Check Username & Save"}
            onPress={async () => { saveInfo(); }}
            style={{
              marginTop: 20,
            }}
            disabled={!changed}
          /> 
        </View>
      } 
      // else {
      //   return <Section style={{margin:80}}>
      //   <SectionContent>
      //       <Text style={{ textAlign: "center", padding: 20 }}>
      //         <b>{profile?.full_name}</b>
      //       </Text>
      //       <Text style={{ textAlign: "center", padding: 20 }}>
      //         TradingView Username:<br/> <b>{profile?.username}</b>
      //       </Text>
      //     </SectionContent>
      //   </Section>
      // }
    }
    return;
  }


  return (
    <Layout>
      <TopNav
        leftContent={(profile?.full_name ? profile?.full_name : '') + ' <'+ user?.user?.email+'>'}
        middleContent={profile?.username ? 'TradingView Username: ' + profile?.username : ''}
        rightContent={<Button
          status="danger"
          text="Logout"
          onPress={async () => {
            const { error } = await supabase.auth.signOut();
          }}
          style={{
            marginTop: 10,
            size: 'sm',
            textStyle:{size:5},
            width: 1
          }}
          size = 'sm'
        />}
        // rightAction={async () => {await supabase.auth.signOut();}}
      />
      {/* <View style={{
          flex: 1,
          alignItems: "center",
          justifyContent: "center",
        }}>
        
      </View> */}
      <ScrollView contentContainerStyle={{
          alignItems: "center",
          justifyContent: "center",
        }}>
        {userInfo()}
        {subscriptions()}
      {/* </View>
      <View style={{
          flex: 1,
          alignItems: "center",
          justifyContent: "center",
        }}> */}
          <Section style={{margin:80}}>
            <SectionContent>
              <Text>
                If you have any questions or issues please email <a style={{color:'#FFFFFF'}} href="mailto:support@marketwebs.com">support@marketwebs.com</a>
              </Text>
            </SectionContent>
          </Section>
        {/* <Section>
          <SectionContent>
            <Button
              status="danger"
              text="Logout"
              onPress={async () => {
                const { error } = await supabase.auth.signOut();
                if (!error) {
                  alert("Signed out!");
                }
                if (error) {
                  alert(error.message);
                }
              }}
              style={{
                marginTop: 10,
              }}
            />
          </SectionContent>
        </Section> */}
      </ScrollView>
    </Layout>
  );
}


const styles = StyleSheet.create({
  link: {
    color: '#000000',
  },
  bigBlue: {
    color: 'blue',
    fontWeight: 'bold',
    fontSize: 30,
  },
  red: {
    color: 'red',
  },
});

