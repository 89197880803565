import React, { createContext, useState, useEffect } from 'react';
import { supabase } from '../initSupabase';
import { Session } from '@supabase/supabase-js';
import { useCookies } from 'react-cookie'
type ContextProps = {
	user: null | boolean;
	session: Session | null;
	admin: boolean;
	// profile: null;
};

const AuthContext = createContext<Partial<ContextProps>>({});

interface Props {
	children: React.ReactNode;
}

const AuthProvider = (props: Props) => {
	// user null = loading
	const [user, setUser] = useState<null | boolean>(null);
	const [session, setSession] = useState<Session | null>(null);
	const [admin, setAdmin] = useState<null | boolean>(false);
	const [cookies, setCookie] = useCookies(['access_token', 'refresh_token', 'promo_code'])

	useEffect(() => {
		async function getSess() {
			const queryParameters = new URLSearchParams(window.location.search)
			if (queryParameters.get('code')) {
				setCookie('promo_code', queryParameters.get('code'));	
			} else {
				setCookie('promo_code', window.location.pathname.replace('/',''));
			}
			const {data,error} = await supabase.auth.refreshSession();
			setSession(data.session);
			setUser(data.session ? true : false);
			if (data && data.session?.user.id !== undefined) {
				const {data:_prof, err } = await supabase.from('profiles').select('*').eq('id', data.session?.user.id);
				if (_prof?.length > 0) {
					if (_prof[0].is_admin) {
						setAdmin(true);
					} else {
						setAdmin(false);
					}
				}
			} else {
				supabase.auth.signOut();	
			}
			
			const { data: authListener } = await supabase.auth.onAuthStateChange(
				async (event, getSession) => {
					console.log(`Supabase auth event: ${event}`);
					
					setSession(getSession);
					setUser(getSession ? true : false);
				}
			);
			return () => {
				authListener!.unsubscribe();
			};
		}
		getSess();
	}, [user]);

	return (
		<AuthContext.Provider
			value={{
				user,
				session,
				admin
			}}
		>
			{props.children}
		</AuthContext.Provider>
	);
};

export { AuthContext, AuthProvider };
