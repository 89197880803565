import { StatusBar } from "expo-status-bar";
import React from "react";
import { ThemeProvider } from "react-native-rapi-ui";
import Navigation from "./src/navigation";
import { AuthProvider } from "./src/provider/AuthProvider";
import { Helmet } from "react-helmet"

export default function App() {
  const images = [
    require("./assets/images/login.png"),
    require("./assets/images/register.png"),
    require("./assets/images/forget.png"),
  ];
  const siteImage = require("./assets/images/SiteImage.png")
  return (
    <>
      <Helmet>
        <script src="https://s3.tradingview.com/tv.js" />
      </Helmet>
      <ThemeProvider images={images}>
        <AuthProvider>
          <Navigation />
        </AuthProvider>
        <StatusBar />
      </ThemeProvider>
    </>
  );
}
