import React, { useContext } from 'react';
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import { AuthContext } from '../provider/AuthProvider';

import { themeColor, useTheme } from "react-native-rapi-ui";
import TabBarIcon from "../components/utils/TabBarIcon";
import TabBarText from "../components/utils/TabBarText";

import Home from "../screens/Home";
import Admin from "../screens/Admin";
import About from "../screens/About";
import Profile from "../screens/Profile";

const Tabs = createBottomTabNavigator();
const MainTabs = () => {
  const auth = useContext(AuthContext);
	const admin = auth.admin;
  const { isDarkmode } = useTheme();
  return (
    <Tabs.Navigator
      screenOptions={{
        headerShown: false,
        tabBarStyle: {
          borderTopColor: isDarkmode ? themeColor.dark100 : "#c0c0c0",
          backgroundColor: isDarkmode ? themeColor.dark200 : "#ffffff",
        },
      }}
    >
      {/* these icons using Ionicons */}
      {!admin && <Tabs.Screen
        name="Home"
        component={Home}
        options={{
          // tabBarLabel: ({ focused }) => (
          //   <TabBarText focused={focused} title="Home" />
          // ),
          tabBarIcon: ({ focused }) => (
            <TabBarIcon focused={focused} icon={"md-home"} />
          ),
        }}
      />}
      {admin && <Tabs.Screen
        name="Admin"
        component={Admin}
        options={{
          // tabBarLabel: ({ focused }) => (
          //   <TabBarText focused={focused} title="Admin" />
          // ),
          tabBarIcon: ({ focused }) => (
            <TabBarIcon focused={focused} icon={"md-settings"} />
          ),
        }}
      />}
      {/* <Tabs.Screen
        name="Profile"
        component={Profile}
        options={{
          tabBarLabel: ({ focused }) => (
            <TabBarText focused={focused} title="Profile" />
          ),
          tabBarIcon: ({ focused }) => (
            <TabBarIcon focused={focused} icon={"person"} />
          ),
        }}
      />
      <Tabs.Screen
        name="About"
        component={About}
        options={{
          tabBarLabel: ({ focused }) => (
            <TabBarText focused={focused} title="About" />
          ),
          tabBarIcon: ({ focused }) => (
            <TabBarIcon focused={focused} icon={"ios-information-circle"} />
          ),
        }}
      /> */}
    </Tabs.Navigator>
  );
};

export default MainTabs;
